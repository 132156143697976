var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#097392","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#097392","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),(_vm.banner)?_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{staticStyle:{"margin-all":"-1px"},attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[(_vm.banner.image)?_c('v-flex',{style:(_vm.$vuetify.breakpoint.name == 'xs'
              ? 'padding-top:0%'
              : 'padding-top:0%'),attrs:{"xs12":"","lg12":"","align-self-center":""}},[_c('v-img',{staticClass:"align-center",attrs:{"contain":_vm.$vuetify.breakpoint.name == 'xs' ? false : true,"src":_vm.mediaURL + _vm.banner.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)}),_c('v-layout',{attrs:{"wrap":"","py-0":"","px-md-2":"","px-lg-16":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-card',{class:_vm.$vuetify.breakpoint.name == 'xs' ? '' : 'bannerGrad rot',style:(_vm.$vuetify.breakpoint.name == 'xs'
                    ? 'margin-top: 0px'
                    : _vm.$vuetify.breakpoint.name == 'sm'
                    ? 'margin-top: 0px'
                    : 'margin-top: -152px'),attrs:{"color":"black"}},[_c('v-layout',{attrs:{"wrap":"","py-8":"","px-14":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":"","md11":"","lg11":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"lg9":"","align-self-center":"","text-justify":""}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'xs'
                              ? 'subxs'
                              : _vm.$vuetify.breakpoint.name == 'sm'
                              ? 'submd'
                              : 'subtitle',staticStyle:{"text-shadow":"4px 4px 8px #000000"},style:('color:' + _vm.banner.textColor)},[_vm._v(" "+_vm._s(_vm.banner.text)+" ")])])],1)],1),_c('v-flex',{attrs:{"sm1":"","md1":"","lg1":"","align-self-center":"","text-right":""}},[_c('v-layout',{staticClass:"d-flex align-end flex-column",attrs:{"wrap":"","justify-end":""}},_vm._l((_vm.socialMediasList),function(item,i){return _c('v-flex',{key:i,staticClass:"hidden-xs-only mt-auto",attrs:{"xs12":"","sm12":"","px-4":"","px-sm-0":"","lg12":"","py-4":"","align-self-end":""}},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('v-img',{attrs:{"contain":"","height":"21px","width":"21px","src":item.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)})],1)])}),1)],1),_c('v-flex',{attrs:{"xs12":"","pt-3":"","align-self-start":"","text-left":""}},[_c('v-btn',{staticClass:"positiontext text-capitalize",staticStyle:{"text-shadow":"4px 4px 6px #000000"},attrs:{"dark":"","elevation":"9","color":"white","outlined":"","to":"/Contact","rounded":""}},[_c('span',[_vm._v("Contact Us   ")]),_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }