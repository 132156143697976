<template>
  <div v-if="banner">
    <Banner :banner="banner" />

    <!-- <About/> -->
    <v-lazy
      v-model="isActive"
      :options="{
        threshold: 0.5,
      }"
      min-height="200"
      transition="fade-transition"
    >
      <Gallery :gallery="gallery" />
    </v-lazy>
  </div>
</template>
<script>
import Banner from "./Banner";
import Gallery from "./Gallery";
import axios from "axios";
export default {
  data() {
    return {
      isActive: false,
      appLoading: false,
      showSnackBar: false,
      timeOut: 5000,
      msg: null,
      gallery: [],
      banner: [],
    };
  },
  components: {
    Banner,
    Gallery,
    // About
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.banner = response.data.data.top;
            this.gallery = response.data.data.gallery.data;
            // this.knowus=this.knownList[0]._id
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>